<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <KTPortlet v-bind:title="'Políticas de Seguridad'">
          
          <template v-slot:body>
<small>Última actualización: 28 marzo de 2022.</small>
<br/><br/> 
<p>starkoms tiene como objetivo corporativo alcanzar y proveer a sus Usuarios la máxima seguridad de los datos personales que procesamos y almacenamos, atendido que dicho objetivo es fundamental para el correcto desarrollo y funcionamiento de nuestro negocio.</p>
<p>A continuación se presenta un resumen de algunos de los procedimientos de seguridad en funcionamiento en starkoms:</p>

<h6 class="mt-3">Acceso a starkoms.</h6>
<p>Requiere una dirección de email y una contraseña. Las contraseñas se almacenan fuertemente encriptadas en nuestra base de datos y usamos encriptación SSL entre el navegador del Usuario y starkoms, por lo que es totalmente seguro.</p>
<h6 class="mt-3">Cuidando tus datos.</h6>
<p>En tu calidad de Usuario tienes plena propiedad sobre tus datos, los mismos que se almacenan en nuestro sistema de base de datos, del que se realiza una copia de seguridad constantemente. </p>
<h6 class="mt-3">¿Qué tan robusto es starkoms?</h6>
<p>starkoms utiliza servicios de servidores en Amazon, Google, Azure y Digital Ocean, lo que brinda una gran cantidad de escalabilidad, seguridad y redundancia. </p>
<p>starkoms utiliza una CDN (red de entrega de contenido) para asegurarse de que sus activos, como imágenes y archivos PDF, se almacenen en caché y sean entregados muy rápido a los navegadores de sus clientes.</p>
<p>Nuestra aplicación está protegida por firewalls y tenemos la capacidad de implementar tecnología de mitigación DDoS en caso de cualquier ataque.</p>
<h6 class="mt-3">Otras medidas de seguridad. </h6>
<p>Otros procedimientos de seguridad (pero no limitados a) realizados por el equipo tecnológico de starkoms son:</br>
&nbsp;&nbsp;&bull;&nbsp;Actualizaciones y parches del sistema de software.</br>
&nbsp;&nbsp;&bull;&nbsp;Monitoreo continúo de interacciones y comportamiento.</br>
&nbsp;&nbsp;&bull;&nbsp;Pruebas automatizadas.</br>
&nbsp;&nbsp;&bull;&nbsp;Gestión de claves y auditorías de acceso.</p>
<h6 class="mt-3">Consultas</h6>
<p>Puedes enviarnos cualquier pregunta o consulta en materia de seguridad de tus datos a <a href="mailto:support@starkoms.com">support@starkoms.com</a>.</p>
<br/><br/> 
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>
<script>
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import KTPortlet from "@/views/partials/content/Portlet.vue";
  export default {
    name: "PrivacyPolicies",
    components: {
      KTPortlet,
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Políticas de Seguridad" }]);
    },
  };
</script>
